/**
  * THIS IS AN AUTO-GENERATED FILE
  * Edit Pollen config to update
  */
:root {
  --size-1: 4px;
  --size-2: 8px;
  --size-3: 12px;
  --size-4: 16px;
  --size-5: 20px;
  --size-6: 24px;
  --size-7: 28px;
  --size-8: 32px;
  --size-9: 36px;
  --size-10: 40px;
  --size-11: 44px;
  --size-12: 48px;
  --size-14: 56px;
  --size-16: 64px;
  --size-20: 80px;
  --size-24: 96px;
  --size-28: 112px;
  --size-32: 128px;
  --size-36: 144px;
  --size-40: 160px;
  --size-44: 176px;
  --size-48: 192px;
  --size-52: 208px;
  --size-56: 224px;
  --size-60: 240px;
  --size-64: 256px;
  --size-72: 288px;
  --size-80: 320px;
  --size-96: 384px;
  --size-px: 1px;
  --size-full: 100%;
  --size-screen: 100vw;
  --size-min: min-content;
  --size-max: max-content;
  --radius-100: 100%;
  --radius-xs: 3px;
  --radius-sm: 6px;
  --radius-md: 8px;
  --radius-lg: 12px;
  --radius-xl: 16px;
  --radius-full: 9999px;
  --shadow-xs: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06);
  --shadow-sm: 0 4px 6px -2px rgba(0, 0, 0, 0.1),
    0 2px 4px -2px rgba(0, 0, 0, 0.06);
  --shadow-md: 0 12px 16px -4px rgba(0, 0, 0, 0.1),
    0 4px 6px -2px rgba(0, 0, 0, 0.05);
  --shadow-lg: 0 20px 24px -4px rgba(0, 0, 0, 0.1),
    0 8px 8px -4px rgba(0, 0, 0, 0.04);
  --shadow-xl: 0 24px 48px -12px rgba(0, 0, 0, 0.25);
  --ease-in-sine: cubic-bezier(0.47, 0, 0.745, 0.715);
  --ease-out-sine: cubic-bezier(0.39, 0.575, 0.565, 1);
  --ease-in-out-sine: cubic-bezier(0.445, 0.05, 0.55, 0.95);
  --ease-in-quad: cubic-bezier(0.55, 0.085, 0.68, 0.53);
  --ease-out-quad: cubic-bezier(0.25, 0.46, 0.45, 0.94);
  --ease-in-out-quad: cubic-bezier(0.455, 0.03, 0.515, 0.955);
  --ease-in-cubic: cubic-bezier(0.55, 0.055, 0.675, 0.19);
  --ease-out-cubic: cubic-bezier(0.215, 0.61, 0.355, 1);
  --ease-in-out-cubic: cubic-bezier(0.645, 0.045, 0.355, 1);
  --ease-in-quart: cubic-bezier(0.895, 0.03, 0.685, 0.22);
  --ease-out-quart: cubic-bezier(0.165, 0.84, 0.44, 1);
  --ease-in-out-quart: cubic-bezier(0.77, 0, 0.175, 1);
  --ease-in-quint: cubic-bezier(0.755, 0.05, 0.855, 0.06);
  --ease-out-quint: cubic-bezier(0.23, 1, 0.32, 1);
  --ease-in-out-quint: cubic-bezier(0.86, 0, 0.07, 1);
  --ease-in-expo: cubic-bezier(0.95, 0.05, 0.795, 0.035);
  --ease-out-expo: cubic-bezier(0.19, 1, 0.22, 1);
  --ease-in-out-expo: cubic-bezier(1, 0, 0, 1);
  --ease-in-circ: cubic-bezier(0.6, 0.04, 0.98, 0.335);
  --ease-out-circ: cubic-bezier(0.075, 0.82, 0.165, 1);
  --ease-in-out-circ: cubic-bezier(0.785, 0.135, 0.15, 0.86);
  --ease-in-back: cubic-bezier(0.6, -0.28, 0.735, 0.045);
  --ease-out-back: cubic-bezier(0.175, 0.885, 0.32, 1.275);
  --ease-in-out-back: cubic-bezier(0.68, -0.55, 0.265, 1.55);
  --easing-standard: cubic-bezier(0.4, 0, 0.2, 1);
  --easing-accelerate: cubic-bezier(0.4, 0, 1, 1);
  --easing-decelerate: cubic-bezier(0, 0, 0.2, 1);
  --color-black: hsl(0 0% 0%);
  --color-black-90: hsla(0, 0%, 0%, 0.9);
  --color-grey-10: hsl(0 0% 10% / 1);
  --color-grey-98: hsl(0 0% 98% / 1);
  --color-white: hsl(50 100% 98%);
  --fs-xs: clamp(0.875rem, 0.875rem + 0vw, 0.875rem);
  --fs-sm: clamp(1.3125rem, 1.3125rem + 0vw, 1.3125rem);
  --fs-md: clamp(
    1.6875rem,
    1.3825214899713467rem + 1.241642788920726vw,
    2.5rem
  );
  --fs-md-h: clamp(
    1.6875rem,
    1.3825214899713467rem + 1.241642788920726vw,
    2.5rem
  );
  --fs-lg: clamp(2rem, 1.4369627507163325rem + 2.292263610315186vw, 3.5rem);
  --fs-xl: clamp(3.125rem, 2.0458452722063036rem + 4.393505253104107vw, 6rem);
  --lh-xs: clamp(0.9375rem, 0.9375rem + 0vw, 0.9375rem);
  --lh-sm: clamp(1.4375rem, 1.4375rem + 0vw, 1.4375rem);
  --lh-md: clamp(
    1.8125rem,
    1.4606017191977076rem + 1.4326647564469914vw,
    2.75rem
  );
  --lh-md-h: clamp(
    1.6875rem,
    1.4294412607449858rem + 1.0506208213944603vw,
    2.375rem
  );
  --lh-lg: clamp(2rem, 1.4838825214899714rem + 2.1012416427889207vw, 3.375rem);
  --lh-xl: clamp(
    2.8125rem,
    1.9444842406876792rem + 3.5339063992359123vw,
    5.125rem
  );
  --ls-lg: clamp(
    0rem,
    0.011729942693409741rem + -0.04775549188156638vw,
    -0.03125rem
  );
  --ls-xl: clamp(
    -0.09375rem,
    -0.07029011461318052rem + -0.09551098376313276vw,
    -0.15625rem
  );
  --dist-1: clamp(0.625rem, 0.625rem + 0vw, 0.625rem);
  --dist-2: clamp(1.25rem, 1.25rem + 0vw, 1.25rem);
  --dist-3: clamp(1.875rem, 1.875rem + 0vw, 1.875rem);
  --dist-5: clamp(3.125rem, 3.125rem + 0vw, 3.125rem);
  --dist-7: clamp(4.375rem, 4.375rem + 0vw, 4.375rem);
  --dist-13: clamp(8.125rem, 8.125rem + 0vw, 8.125rem);
  --dist-17: clamp(10.625rem, 10.625rem + 0vw, 10.625rem);
  --dist-1-5: clamp(0.9375rem, 0.9375rem + 0vw, 0.9375rem);
  --lh-factor-sm: 1.0952380952380953;
  --lh-factor-md: 1.0740740740740742;
}

@media (min-width: 1440px) {
  :root {
    --fs-xs: clamp(0.875rem, 0.875rem + 0vw, 0.875rem);
    --fs-sm: clamp(1.3125rem, 1.3125rem + 0vw, 1.3125rem);
    --fs-md: clamp(2.5rem, 2.5rem + 0vw, 2.5rem);
    --fs-md-h: clamp(2.5rem, 2.5rem + 0vw, 2.5rem);
    --fs-lg: clamp(3.5rem, 3.5rem + 0vw, 3.5rem);
    --fs-xl: clamp(6rem, 6rem + 0vw, 6rem);
    --lh-xs: clamp(0.9375rem, 0.9375rem + 0vw, 0.9375rem);
    --lh-sm: clamp(1.4375rem, 1.4375rem + 0vw, 1.4375rem);
    --lh-md: clamp(2.75rem, 2.75rem + 0vw, 2.75rem);
    --lh-md-h: clamp(2.375rem, 2.375rem + 0vw, 2.375rem);
    --lh-lg: clamp(3.375rem, 3.375rem + 0vw, 3.375rem);
    --lh-xl: clamp(5.125rem, 5.125rem + 0vw, 5.125rem);
    --ls-lg: clamp(-0.03125rem, -0.03125rem + 0vw, -0.03125rem);
    --ls-xl: clamp(-0.15625rem, -0.15625rem + 0vw, -0.15625rem);
    --dist-1: clamp(0.625rem, 0.625rem + 0vw, 0.625rem);
    --dist-2: clamp(1.25rem, 1.25rem + 0vw, 1.25rem);
    --dist-3: clamp(1.875rem, 1.875rem + 0vw, 1.875rem);
    --dist-5: clamp(3.125rem, 3.125rem + 0vw, 3.125rem);
    --dist-7: clamp(4.375rem, 4.375rem + 0vw, 4.375rem);
    --dist-13: clamp(8.125rem, 8.125rem + 0vw, 8.125rem);
    --dist-17: clamp(10.625rem, 10.625rem + 0vw, 10.625rem);
    --dist-1-5: clamp(0.9375rem, 0.9375rem + 0vw, 0.9375rem);
    --lh-factor-sm: 1.0952380952380953;
    --lh-factor-md: 1.1;
  }
}
