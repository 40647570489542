@import "./styles/reset.css";
@import "./styles/vars.css";

@font-face {
  font-family: "Eliza-Regular";
  src: url("/fonts/Eliza-Regular.woff2") format("woff2");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Eliza-Regular";
  src: url("/fonts/Eliza-RegularItalic.woff2") format("woff2");
  font-weight: normal;
  font-style: italic;
}

@font-face {
  font-family: "ABCROM-Regular";
  src: url("/fonts/ABCROM-Regular.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "ABCROMMono-Regular";
  src: url("/fonts/ABCROMMono-Regular.woff2") format("woff2");
  font-weight: normal;
  font-style: normal;
}

:root {
  --global-columns: 4;
  --global-gutter: var(--dist-1-5);
  --global-gutters: calc((var(--global-columns) - 1) * var(--global-gutter));
  --global-column: calc(
    (100vw - 2 * var(--dist-1) - var(--global-gutters)) / var(--global-columns)
  );

  --color-secondary: var(--color-white);
  --color-primary: var(--color-black);
  --color-secondary-light: hsl(0, 0%, 98%);
  --color-primary-overlay: hsla(0 0% 0% / 0.9);
  --color-secondary-overlay: hsla(50 100% 98% / 0.9);

  /* Cookie Consent */
  --cc-modal-border-radius: 0;
  --cc-link-color: currentColor;
}

@media (min-width: 1024px) {
  :root {
    --global-columns: 12;
  }
}

body {
  font-family: "ABCROM-Regular";
  font-size: var(--fs-sm);
  line-height: var(--lh-sm);
  background: var(--color-secondary);
  color: var(--color-primary);
}

html,
body {
  -ms-overflow-style: none;
  scrollbar-width: none;
}

html::-webkit-scrollbar,
body::-webkit-scrollbar {
  display: none;
}

#__next {
  display: flex;
  flex-direction: column;
  min-height: calc(100 * var(--svh));
}

body header + article {
  flex-grow: 1;
}

p a {
  display: inline-block;
  max-width: min(50ch, 100%);
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

a {
  cursor: pointer;
}
